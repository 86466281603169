/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W latach 2020-2021 roku toczyły się prace związane z budową drogi ekspresowej S5 w miejscowości Stęszew, województwo wielkopolskie."), "\n", React.createElement(_components.p, null, "Firma Transpol Lider Sp. z o.o. Sp. k. w ramach inwestycji wykonała między innymi:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "przebudowę urządzeń drenarskich: wykopy i przekopy, umocnienia ścian wykopów wraz z demontażem umocnienia,"), "\n", React.createElement(_components.li, null, "mechaniczne i ręczne zasypanie wykopów wraz z zagęszczeniem gruntu,"), "\n", React.createElement(_components.li, null, "montaż systemowych studzienek PEHD, czyszczenie studzienek drenarskich;"), "\n", React.createElement(_components.li, null, "dokończenia budowy zbiorników retencyjno-infiltracyjnych"), "\n", React.createElement(_components.li, null, "przełożenie płyt ażurowych w wykonanych zbiornikach, odmulenie wykonanych zbiorników,"), "\n", React.createElement(_components.li, null, "wykonanie wylotów kanalizacji deszczowych DN 300 i DN 400,"), "\n", React.createElement(_components.li, null, "roboty ziemne w gruncie z transportem urobku"), "\n", React.createElement(_components.li, null, "wykonanie podsypki filtracyjnej, wzmacnianie powierzchni geowłókninami na dnie i skarpach,"), "\n", React.createElement(_components.li, null, "montaż zabezpieczenia zbiorników płytami ażurowymi wypełnionych warstwą filtracyjną na dnie i skarpach,"), "\n", React.createElement(_components.li, null, "wykonanie umocnienia skarpy zbiorników elementami prefabrykowanymi typu „L”"), "\n"), "\n", React.createElement(_components.p, null, "Inwestycja została oddana do użytku w marcu 2021 roku."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
